/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, TitleMain, Title, Button, ColumnCard, SeparateLine, SeparateLineWrap, SeparateLineElement, Subtitle, Image, Clearfix, Form } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-12185qf --style3 --full mt--0 pb--0 pt--0" name={"uvod"} style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2153/59fd0b9ee4424e9f8c68e3ea6e443da1_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--invert" content={""}>
              </Text>

              <TitleMain className="title-box ff--1 fs--72 title-box--invert mt--0" style={{"marginTop":null}} content={""}>
              </TitleMain>

              <Title className="title-box title-box--center w--500 title-box--invert ls--0 mt--40" style={{"marginTop":null,"backgroundColor":"rgba(77, 25, 69, 0.48)"}} content={"<a href=\"#uvod\">Genealogické služby - Miroslava Kutáková&nbsp;&nbsp;</a>"}>
              </Title>

              <Button className="btn-box btn-box--style3" href={""} content={"<span style=\"color: rgb(255, 255, 255); font-style: italic;\">\"Každý z nás si v sobě neseme otisky lidí, kteří tu byli před námi\"</span>&nbsp;<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"pwhwy6v8pph"} style={{"backgroundColor":"rgba(144, 19, 254, 0.15)"}} layout={"l21"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(74, 74, 74);\">Objevte své kořeny</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnCard className="column__card">
              
              <ColumnWrapper className="--shadow pb--16 pt--16" style={{"backgroundColor":"#ffffff"}}>
                
                <Text className="text-box text-box--left lh--16" style={{"maxWidth":1010,"fontStyle":"italic"}} content={"Zajímá Vás, kdo byli Vaši předci? Pomohu Vám objevit Vaše kořeny a rodovou historii.&nbsp;<br>Genealogie není jen o hledání jmen a dat. Je to cesta, jak poznat svou rodovou historii, porozumět tradicím a hodnotám, které se předávaly z generace na generaci. Zjistit, odkud pochází Vaše rody, může být fascinující a obohacující zkušeností. Mnoho lidí se rozhoduje pro genealogický výzkum, aby si ucitili památku svých předků a lépe porozuměli svému místu na světě.&nbsp;<br><br>Nabízím služby v oblasti genealogie, zabývám se tvorbou rodokmenů a rodové historie: vyhledám původ Vašich předků, místa, původu rodu atd. Specializuji se především na Plzeňský kraj, Jihočeský kraj a Středočeský kraj, ale své služby mohu nabídnout pro celou Českou republiku .&nbsp;<br><br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnCard>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"uxsf24u7n7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Jak začít s pátráním po svých předcích&nbsp;"}>
              </Title>

              <Button className="btn-box btn-box--left" style={{"backgroundColor":"rgba(147,87,159,1)"}} content={"Pokud se rozhodnete začít s pátráním po svých předcích, je dobré mít na paměti několik základních informací.&nbsp;<br>Pro úspěšné provedení genealogického výzkumu je důležité mít alespoň základní informace o Vašich předcích. Tyto informace mohou zahrnovat: Jména a příjmení<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Data a místa narození, svatby a úmrtí<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Rodinné vazby a vztahy<br>Pokud potřebné matriky nejsou uloženy v archivu, ale jsou na městském nebo obecním úřadě, je třeba doložit písemné zmocnění a další doklady dokazující příbuzenství.&nbsp;<br><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"bpqgjw99hka"} layout={"l4"}>
        </SeparateLine>


        <Column className="bg--bottom pb--30 pt--30" name={"u10kcje7b9q"} style={{"backgroundColor":"rgba(120, 16, 224, 0.54)"}} layout={"l21"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(74, 74, 74);\">Služby<a href=\"#zxj3z8t72p\">&nbsp;+ cen</a>y</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnCard className="column__card">
              
              <ColumnWrapper className="--shadow pb--16 pt--16" style={{"backgroundColor":"#ffffff"}}>
                
                <Text className="text-box text-box--left" style={{"maxWidth":972,"fontStyle":"italic"}} content={"<span style=\"font-weight: bold;\">1) Vývod předků </span>(nejčastější typ)<br>vyhledání všech předků od určeného jedince (narozeného po roce 1920). Počet předků stoupá geometrickou řadou tzn.&nbsp;<br>1. generace&nbsp; = osoba, jejíž původ se bude sledovat<br>2. generace = 2 osoby (rodiče)<br>3. generace = 4 osoby (dědečkové, babičky)<br>4. generace = 8 osob (pradědové, prababičky) cena dohodou<br>5. generace = 16 osob (prapradědové, praprababičky) cena dohodou<br>6. generace = 32 osob (praprapradědové, prapraprababičky) cena dohodou<br><br><span style=\"font-weight: bold;\">2) Otcovská linie </span>(pátrá se po předcích v mužské linii)<br>přímá linie výchozí osoba - její otec - její děd - její praděd.....&nbsp;<br>cena od 2800,- Kč&nbsp;<br><br><span style=\"font-weight: bold;\">3) Mateřská linie</span> (pátrá se po předcích v ženské linii)&nbsp;<br>přímá linie výchozí osoba - její matka - její babička - její prababička .....<br>cena od 2800,- Kč<br><br><span style=\"font-weight: bold;\">4) Rodokmen rodu</span> (nejnáročnější typ)<br>hledají se všichni potomci určené osoby, zde může jít o velké množství osob<br>cena dohodou&nbsp;<br><br><br><span style=\"font-weight: bold;\">5)  Pátrání po jednotlivých osobách</span>\n<br>např. by Vás zajímaly údaje o předkovi, o němž nemáte informace, zajímá Vás jeho osud, jeho majetkové poměry atd. <br>&nbsp;cena: od 1500,- Kč&nbsp;<br><br><span style=\"font-weight: bold;\">6) Vyhledání jednoho matričního záznamu</span> (narození, svatba, úmrtí)<br>cena 150,- Kč&nbsp;<br><br><br><br>Každá zakázka je odevzdána v digitální podobě či vytištěna, v případě zájmu doplněna kopiemi záznamů v elektronické podobě či tištěné podobě, vše je otázkou vzájemné domluvy.&nbsp;<br><br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnCard>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"delici prvek"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <SeparateLine className="pb--10 pt--10" name={"w2n615wxtg"}>
          
          <SeparateLineWrap >
            
            <SeparateLineElement >
            </SeparateLineElement>

          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"hvk70wk1iws"} layout={"l21"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"O mně&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"paddingTop":44}} columns={"1"}>
            
            <ColumnCard className="column__card">
              
              <ColumnWrapper className="--shadow pb--16 pt--16" style={{"backgroundColor":"#ffffff"}}>
                
                <Text className="text-box text-box--left lh--16" style={{"fontStyle":"italic"}} content={"„Již od mládí jsem se zajímala o staré dokumenty, které jsem objevila po svých předcích. Začínala jsem s hledáním vlastních kořenů a zpracováním historie vlastní rodiny. Bohužel mi nebylo dáno studovat historii, mám technické vzdělání. V roce 2004 jsem absolvovala Kurz pro začínající rodopisce a kronikáře a v roce 2005 jsem absolvovala Kurz pro pokročilé genealogy pořádané Českou genealogickou a heraldickou společností v Praze. Tyto kurzy mi poskytly potřebné znalosti a dovednosti pro práci genealogie a kronikáře. V současné době mi umožnila moje rodinná situace věnovat se tomuto mému velkému koníčku.&nbsp;"}>
                </Text>

              </ColumnWrapper>

            </ColumnCard>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"delici-prvek2"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--l8w bg--center pb--30 pt--30" name={"fotogalerie"} style={{"backgroundColor":"rgba(74, 144, 226, 0.36)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Ukázky"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2153/2969fae4a64746d98cd31b1cc0d9b191_s=860x_.JPEG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/2969fae4a64746d98cd31b1cc0d9b191_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/2153/2969fae4a64746d98cd31b1cc0d9b191_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/2153/2969fae4a64746d98cd31b1cc0d9b191_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/2153/2969fae4a64746d98cd31b1cc0d9b191_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/2153/2969fae4a64746d98cd31b1cc0d9b191_s=2000x_.JPEG 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Vývod z 8 předků"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2153/c440401cba9545f8844aa046078bbe37_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"marginBottom":29}} srcSet={"https://cdn.swbpg.com/t/2153/c440401cba9545f8844aa046078bbe37_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2153/c440401cba9545f8844aa046078bbe37_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2153/c440401cba9545f8844aa046078bbe37_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2153/c440401cba9545f8844aa046078bbe37_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Fotokniha historie rodu"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--justify">
              
              <Image src={"https://cdn.swbpg.com/t/2153/4f5d2c2b64d34bdf9346dcf2ac4042b5_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/4f5d2c2b64d34bdf9346dcf2ac4042b5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2153/4f5d2c2b64d34bdf9346dcf2ac4042b5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2153/4f5d2c2b64d34bdf9346dcf2ac4042b5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2153/4f5d2c2b64d34bdf9346dcf2ac4042b5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2153/4f5d2c2b64d34bdf9346dcf2ac4042b5_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left" content={"&nbsp;Fotokniha historie rodu"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2153/a7a228d3a49c42e78a3ed7a15a1bebca_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/a7a228d3a49c42e78a3ed7a15a1bebca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2153/a7a228d3a49c42e78a3ed7a15a1bebca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2153/a7a228d3a49c42e78a3ed7a15a1bebca_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2153/a7a228d3a49c42e78a3ed7a15a1bebca_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2153/a7a228d3a49c42e78a3ed7a15a1bebca_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Fotokniha historie rodu"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"0q6gl6slejek"} layout={"l9"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2153/70f2a4728d044d55a99d7bc6c7033726_s=860x_.JPEG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/70f2a4728d044d55a99d7bc6c7033726_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/2153/70f2a4728d044d55a99d7bc6c7033726_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/2153/70f2a4728d044d55a99d7bc6c7033726_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/2153/70f2a4728d044d55a99d7bc6c7033726_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/2153/70f2a4728d044d55a99d7bc6c7033726_s=2000x_.JPEG 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Vložte popis obrázku"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2153/67057ddb8df24c5d842429273120b56a_s=860x_.JPEG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/67057ddb8df24c5d842429273120b56a_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/2153/67057ddb8df24c5d842429273120b56a_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/2153/67057ddb8df24c5d842429273120b56a_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/2153/67057ddb8df24c5d842429273120b56a_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/2153/67057ddb8df24c5d842429273120b56a_s=2000x_.JPEG 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Vložte popis obrázku"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/o/2153/d8ae9831c306458193c21b034dfc6706.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Autor: Miroslava Kutáková ISBN: 9788027044849 NKP-CNB: cnb003008572 OKCZID: 127924719 Citace (dle ČSN ISO 690): KUTÁKOVÁ, Miroslava. Újezd u Kasejovic: pohledy do historie a současnosti obce. [Kasejovice]: Město Kasejovice, 2018. 150 stran.u"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

          </ColumnWrap>

          <Clearfix >
          </Clearfix>

        </Column>


        <Column className="pb--60 pt--60" name={"jftdacx0f0p"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box title-box--center" content={"Galerie&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"eelqoe941p7"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2153/37c2659a58a6492aa58b5919f727d7d4_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/37c2659a58a6492aa58b5919f727d7d4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2153/37c2659a58a6492aa58b5919f727d7d4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2153/37c2659a58a6492aa58b5919f727d7d4_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"Prokop a Marie Hulačovi, prapraprarodiče&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2153/a04278b3aeef4492ba0e1abdacb2be16_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/a04278b3aeef4492ba0e1abdacb2be16_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2153/a04278b3aeef4492ba0e1abdacb2be16_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2153/a04278b3aeef4492ba0e1abdacb2be16_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2153/a04278b3aeef4492ba0e1abdacb2be16_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2153/a04278b3aeef4492ba0e1abdacb2be16_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"Josef a Marie Hulačovi, praprarodiče<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2153/822910bda7b94a19b6dfc754c96874cb_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/822910bda7b94a19b6dfc754c96874cb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2153/822910bda7b94a19b6dfc754c96874cb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2153/822910bda7b94a19b6dfc754c96874cb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2153/822910bda7b94a19b6dfc754c96874cb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2153/822910bda7b94a19b6dfc754c96874cb_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"František a Růžena Kadaňovi, prarodiče"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2153/d2f29b880c024939afbcbf53d78bf303_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2153/d2f29b880c024939afbcbf53d78bf303_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2153/d2f29b880c024939afbcbf53d78bf303_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2153/d2f29b880c024939afbcbf53d78bf303_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2153/d2f29b880c024939afbcbf53d78bf303_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2153/d2f29b880c024939afbcbf53d78bf303_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box" content={"moji rodiče"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"rfnzuvj19z"} style={{"marginTop":1.75}} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"delici-prvek3"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"b777wvfq5en"} style={{"backgroundColor":"rgba(0, 0, 0, 0.92)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box title-box--center title-box--invert" content={"Kontaktní formulář"}>
              </Title>

              <Text className="text-box text-box--invert" style={{"maxWidth":600}} content={"Máte dotaz? Potřebujete poradit? Nebo mi chcete něco sdělit? <br>Napište mi pomocí kontaktního formuláře - já se Vám zpětně ozvu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Form className="--invert" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l3"}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--invert pb--40 pt--40" name={"fbnaixajrpm"} style={{"backgroundColor":"var(--color-main-bg-2)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--20" name={"paticka"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"var(--color-variable-1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24 w--500 title-box--invert" content={"Miroslava Kutáková"}>
              </Title>

              <Text className="text-box fs--16 text-box--invert" content={"www.rodokmeny-kutakova.cz"}>
              </Text>

              <Text className="text-box ff--1 w--300 ls--01" content={"<span style=\"color: rgb(255, 255, 255);\">IČ: 07911807</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--26 w--500 title-box--invert" content={"Kontakt:"}>
              </Title>

              <Subtitle className="subtitle-box w--500 subtitle-box--invert ls--001" content={"+420 737183106"}>
              </Subtitle>

              <Text className="text-box w--600 text-box--invert mt--02" content={"info@rodokmeny-kutakova.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box fs--18 w--600 mt--0" content={"<a href=\"#paticka\">Úvod</a><br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 mt--0" content={"Služby<a href=\"#u10kcje7b9q\">/</a>Ceník<br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#fotogalerie\">Fotogalerie</a>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#rezervace\">Kontakt</a>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#uvod\">Zpět nahoru</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}